import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Container, Header, Grid } from "semantic-ui-react";
import FSILogo from '../../static/images/FullStoryInitiative_Logo.png';
import NRDCLogo from '../../static/images/nrdc-logo.png';
import FoundationLogo from '../../static/images/caa-foundation-logo-stacked.png';
import styled from "styled-components";
import Layout from "../components/Layout";
import ToolkitForm, { IToolkitFormState } from "../components/ToolkitForm";
import { MainHeader, FulStoryButton } from "../helpers/styledComponents";
import { useUserState, useUserActions } from "../components/Layout";
import VideoContainer from "../components/VideoContainer";
import { useIntercom } from "react-use-intercom";

const EVENT = {
  name: "climate",
  title: 'Envisioning A Livable Planet: Storytellers and The Climate Crisis',  
  starts: '2021-04-23T18:00:00.001Z',
  ends: '2021-04-23T19:30:00.000Z',
  preroll: 15 // 15 minutes
};

const PageContainer = styled(Container)`
  .instructions{
    color: #FAB60B;
    text-transform: uppercase;
  }
  text-align: center;
`;

const VideoSection = styled.div`
  .videoDescriptionContainer{
    margin-top: 4em;
  }

  .downloadButtons {
    margin: 0 auto 3em;
    max-width: 450px;
    .ui.button{
      display: block;
      margin: 1.3em auto;
    }
  }

  .logoGrid{
    max-width: 600px;
    margin: auto;
    
    img {
      width: auto;
      max-height: 70px;
      &.caaFoundation{
        max-height: 100px;
      }
    }
    @media(min-width: 768px){
      img {
        max-height: 100px;
        &.caaFoundation{
          max-height: 150px;
        }
      }
    }
  }
`;

const UserFormContainer : React.FC <{setOpenModal : (open: boolean) => void}> = ({setOpenModal} ) => {
  const user = useUserState();
  const { saveUser }  = useUserActions();
  const { boot, hide, trackEvent } = useIntercom()
  const eventStarted = new Date().getTime() > new Date(EVENT.starts).getTime() - (EVENT.preroll * 60 * 1000);
  const eventEnded = new Date().getTime() > new Date(EVENT.ends).getTime();

  useEffect(() => {
    window.scrollTo(0, 0);

    if(user.email) {
      boot();
      hide();
      if(eventStarted && !eventEnded) {
        trackEvent(`${EVENT.name}_Viewed`);
      }
      else {
        trackEvent(`${EVENT.name}_Registered`);
      }
    }
  }, [user.email]);

  const handleSubscribe = (data: IToolkitFormState) => {   
    setOpenModal(true);
    trackEvent("subscribed", { event: EVENT.name });
  }

  const resetUser = () => {
    saveUser({
      name: "",
      email: "",
      company: user.company,
      position: "",
      reason: EVENT.name,
      optin: user.optin
    });
  }

  return (
    <>   
    {(!user.email && !eventEnded) && (
      <>
        <MainHeader>
          <Header className="h3 light">
            {EVENT.title}
          </Header>
          <Header className="compact dark">
              A convening of entertainment industry leaders, scientists and activists about the role of film, tv, and digital media in saving our planet.
          </Header>
          <Header className="h3 dark ">
            {new Date(EVENT.starts).toLocaleDateString([], { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}<br />
            {new Date(EVENT.starts).toLocaleTimeString([], {hour: 'numeric', minute: 'numeric', timeZoneName: 'short'})}
          </Header>
        </MainHeader>
        <ToolkitForm onSubmitHandler={handleSubscribe} />
        <Header as="h2" textAlign="center" className="instructions">
          CLICK SUBMIT TO REGISTER
        </Header>
      </>
    )}

    {(user.email || eventEnded) && (
      <VideoSection>
        {!eventEnded && (
          <VideoContainer src="//player.cloud.wowza.com/hosted/4fvc4mbr/wowza.js" />
        )}
        <MainHeader className="videoDescriptionContainer">
          {!eventEnded && (
            <>
              <Header className="h3">
                {EVENT.title}
              </Header>
              <Header className="h4 dark">
                A convening of entertainment industry leaders, scientists and activists about the role of film, tv, and digital media in saving our planet.
              </Header>
              <Header className="h3 dark">
                {new Date(EVENT.starts).toLocaleDateString([], { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}<br />
                {new Date(EVENT.starts).toLocaleTimeString([], {hour: 'numeric', minute: 'numeric', timeZoneName: 'short'})}
              </Header>

              <FulStoryButton customcolor="yellow" size="huge" content="register another" onClick={resetUser} />

              <Grid centered verticalAlign="middle" className="logoGrid">
                <Grid.Row columns="3">
                  <Grid.Column textAlign="center">
                    <img src={FSILogo}  alt="Full Story Initiative Logo" />
                  </Grid.Column>
                  <Grid.Column textAlign="center">
                    <img src={FoundationLogo} className="caaFoundation" alt="CAA Foundation Logo" />
                  </Grid.Column>
                  <Grid.Column textAlign="center">
                    <img src={NRDCLogo} alt="National Resources Defense Council Logo" />
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              <Header className="h4 dark">
                Hosted by Full Story Initiative, CAA Foundation, and NRDC
              </Header>
            </>
          )}
          {eventEnded && (
            <>
              <Header className="h3">
                Thank you for visiting
              </Header>
              <Header className="h4 dark">
                The event has concluded
              </Header>
            </>
          )}

          <div className="downloadButtons">
            <FulStoryButton customcolor="yellow" size="huge" content="Download the Full Story Initiative Toolkit" href="/Full_Story_Toolkit.pdf" target="_blank" />
            {eventStarted && (
              <FulStoryButton customcolor="yellow" size="huge" content="Download the Climate Take Action Toolkit" href="/Climate_Toolkit.pdf" target="_blank" />
            )}
          </div>

          {!eventEnded && (
            <>
              <Header className="h4 dark">
                Follow Us
              </Header>
              <Header className="h4 compact">              
                <a href="https://www.instagram.com/caafoundation/?hl=en" target="_blank">@caafoundation</a><br />
                <a href="https://www.instagram.com/nrdc_org/?hl=en" target="_blank">@nrdc_org</a>
              </Header>
            </>
          )}
        </MainHeader>
      </VideoSection>
    )}
    </>
  );
}

export default () => {
  const [openModal, setOpenModal] = useState(false);
  
  return (
    <>
      <Helmet>
        <title>{EVENT.title} </title>
        <meta name="title" content={EVENT.title} />
        <meta name="og:title" content={EVENT.title} />
        <meta name="twitter:title" content={EVENT.title} />
        <meta name="og:site_name" content="Live | Full Story Initiative" />
      </Helmet> 
      <Layout>
        <PageContainer>
          <UserFormContainer setOpenModal={setOpenModal}  />
        </PageContainer>
      </Layout>
    </>
  )
};
