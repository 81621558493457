import React, { useLayoutEffect } from "react";
import styled from "styled-components";

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  // padding-top: 56.25%; /* 16:9 Aspect Ratio */
  background-color: #fff;
  
  // iframe {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   bottom: 0;
  //   right: 0;
  //   width: 100%;
  //   height: 100%;
  //   border: none;
  // }
`;

interface IVideoContainerProps {
  src: string,
  className?: string
}

export default ({ src, className }: IVideoContainerProps)=>{
  // Vimeo: <iframe src={src || ""} frameBorder="0" allow="autoplay; fullscreen"></iframe>
  // Wowza: <script id='player_embed' src={src || ""} type='text/javascript'></script>
  useLayoutEffect(() => {
    const script = document.createElement('script');

    script.src = src;
    script.type= 'text/javascript';
    script.async = true; 

    window.setTimeout( () => {
      document.body.appendChild(script);
    }, 500);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return(
    <VideoContainer className={className}>
      <div id='wowza_player'></div>
    </VideoContainer>
  )
}